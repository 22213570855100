import React, { ReactNode } from 'react';
import { toast, useToaster } from 'react-hot-toast';
import { AnimatePresence, motion } from 'framer-motion';
import Warning from '../../assets/icons/Warning';
import Checked from '../../assets/icons/Checked';
import Close from '../../assets/icons/Close';
import { IconButton } from '../IconButton';
import { ToastOptions } from 'react-hot-toast/dist/core/types';

const toastBodyStyles = {
  blank: 'border-blue-500',
  success: 'border-green-500',
  error: 'border-red-500',
  loading: '',
} as const;

export const ToasterHub = () => {
  const { handlers, visibleToasts } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div
      className="fixed right-2 top-10 space-y-4 transform -translate-x-1/2 left-1/2 right-auto flex flex-col justify-center items-center z-50"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      <AnimatePresence>
        {visibleToasts.map((toast) => (
          <Toast
            key={toast.id}
            id={toast.id}
            role={toast.role}
            ariaLive={toast.ariaLive}
            type={toast.type}
            message={toast.message}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

interface ToastTypes {
  role: string;
  ariaLive: 'off' | 'assertive' | 'polite';
  type: keyof typeof toastBodyStyles;
  message: ReactNode;
  id: string;
}

const iconsForToast = {
  blank: Warning,
  success: Checked,
  error: Close,
  loading: null,
} as const;
const iconsStyleForToast = {
  blank: 'text-blue-500 bg-blue-100',
  success: 'text-green-500 bg-green-100',
  error: 'text-red-500 bg-red-100',
  loading: null,
} as const;

const Icon = ({ type }: Pick<ToastTypes, 'type'>) => {
  const MaybeIcon = iconsForToast[type];
  if (!MaybeIcon) {
    return null;
  }

  return (
    <div
      className={`h-6 w-6 mr-4 rounded-full flex justify-center align-center items-center ${iconsStyleForToast[type]}`}
    >
      <MaybeIcon className="h-4 w-4" />
    </div>
  );
};

export const Toast = ({ id, role, ariaLive, type, message }: ToastTypes) => {
  return (
    <motion.div
      key={id}
      role={role}
      aria-live={ariaLive}
      layout
      className="flex"
      initial={{ opacity: 0, y: -200, scale: 0.3, zIndex: 0 }}
      animate={{ opacity: 1, y: 0, scale: 1, zIndex: 10 }}
      exit={{ opacity: 0, y: -100, scale: 0.3, zIndex: 0 }}
    >
      <div
        className={`min-h-16 max-w-xl border-t-2 pl-4 pr-2 py-2 rounded shadow flex items-center bg-white ${toastBodyStyles[type]}`}
      >
        <Icon type={type} />
        {message}
        <IconButton
          className="ml-2"
          alt="Close"
          icon={Close}
          size="s"
          variant="ghost"
          onClick={() => toast.dismiss(id)}
        />
      </div>
    </motion.div>
  );
};

export const ToastBody = ({ title, body }: { title: string; body: string }) => {
  return (
    <div>
      <div className="font-semibold leading-normal">{title}</div>
      <div className="text-gray-500">{body}</div>
    </div>
  );
};

interface ShowToastProps extends ToastOptions {
  type: 'error' | 'success' | 'info';
  title: string;
  description: string;
}

export const showToast = ({ description, title, type, ...rest }: ShowToastProps) => {
  const body = <ToastBody title={title} body={description} />;

  switch (type) {
    case 'error':
      return toast.error(body, rest);
    case 'info':
      return toast(body, rest);
    case 'success':
      return toast.success(body, rest);
  }
  throw new Error('Unknown toast type');
};
